import schedular from "../calendar/staffSchedular";
import {useRef, useState, useEffect, useCallback} from "react";
import getData from "../fetch-data/fetch.data";
import { getAllTherapists } from "./../fetch-data/my-api";
import {NavLink, useHistory} from 'react-router-dom'

import postSchedule from "../fetch-data/post.schedule";
import updateSchedule from "../fetch-data/update.schedule";
import user_info from "../fetch-data/user.info";
import toast, { Toaster } from 'react-hot-toast';

function StaffSchedule(){
    const [monthCal, setmonthCal] = useState([])
    const dateRef = useRef()
    const timesRef = useRef()

    const navigation = useHistory();

    const [isavailWeekend, setisavailWeekend] = useState(true)
    const [isdeleted, setisdeleted] = useState(false)
    const [timeHandle, setTimeHandle] = useState([])
    const [existsMessage, setexistsMessage] = useState("")
    const [isTimeInArray, setisTimeInArray] = useState(false)
    const [schedules, setSchedules] = useState([])
    const [fetchError, setfetchError] = useState("")
    const [isFetched, setisFetched] = useState(false)
    const [isLoaded, setisLoaded] = useState(false)

    const [isPosted, setisPosted] = useState(false)
    const [Response, setResponse] = useState([])
    const [isEmpty, setisEmpty] = useState(false)
    const [timeMessage, settimeMessage] = useState("")
    const [isTime, setisTime] = useState(false)
    const [isSaved, setisSaved] = useState(true)

    const [userInfo, setuserInfo] = useState([])
    const [token, setToken] = useState('');

    const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
    const [therapist, setTherapist] = useState(null)

    useEffect(()=>{

        const abortController = new AbortController()

        setmonthCal(schedular())
        getData(
            setSchedules,
            setfetchError,
            setisFetched,
            setisLoaded
            )

            setisSaved(false)

        const access_token = sessionStorage.getItem("access_token")
        setToken(access_token)

        user_info(access_token, (data) => {
            setuserInfo(JSON.parse(data));
          });



        // userInfo.map

        // console.log("Times:", timeData)
        return () => {
            abortController.abort()
            // stop the query by aborting on the AbortController on unmount
          }

      }, [isSaved])

      useEffect( () => {
           let all_therapist = getAllTherapists().then( (therapists) => {
                if (therapists.data.length > 0) {
                     let currentTherapist = therapists.data.find((therapist, index) =>{
                          return therapist.email == user.email
                     })
                     if(currentTherapist !== undefined)
                     {
                          setTherapist(currentTherapist)
                          sessionStorage.setItem("therapist_bio", JSON.stringify(currentTherapist))
                     }else{
                          toast.error("Kindly update your bio on the profile page to continue", {position: 'top-right'})
                          setTimeout(() => {
                               navigation.push('/admin/profile')
                          }, 2000)
                     }
                }
           }).catch( (error) => {
                console.log(error)
           })
      }, [])

      const saveAction=useCallback((cellValue)=>{
        //Todo: Post data from table to database

        const toastId = toast.loading("Scheduling chosen time ... ", {position: 'top-right'})

        if(userInfo.length<1){
            settimeMessage("Your session has expired, please login again")
        }else{

        // const userarray = JSON.parse(userInfo)

        setisSaved(true)


        const getNames = (schedules.map(x=>Object.keys(x)[4]))

        const currentNameIndex = getNames.indexOf(userInfo.first_name)

        const staffSchedule = schedules.map(x=> {
            return x[userInfo.first_name]
        })

        console.log(timeHandle.length)


        if(timeHandle.length >=1){

            setisTime(true)

            const daysArray = staffSchedule[currentNameIndex]

            if (daysArray===undefined){

                setisEmpty(true)
                postSchedule(
                    userInfo.first_name,
                    userInfo.last_name,
                    userInfo.email,
                    cellValue,
                    timeHandle,
                    setisPosted,
                    setResponse
                )
                toast.success("Schedule completed succesfully", {position: 'top-right', id: toastId})
                setTimeHandle([])

                console.log("Go", daysArray===undefined)
            }else if(daysArray.length<1){

                updateSchedule(
                    userInfo.first_name,
                    userInfo.last_name,
                    userInfo.email,
                    cellValue,
                    timeHandle,
                    setisPosted,
                    setResponse
                )

                setTimeHandle([])

                console.log("Elseif",daysArray.length)
            }else{

                updateSchedule(
                    userInfo.first_name,
                    userInfo.last_name,
                    userInfo.email,
                    cellValue,
                    timeHandle,
                    setisPosted,
                    setResponse
                )

                setTimeHandle([])
            }
        }else{
            setisTime(false)
            settimeMessage("You cannot submit blank times")
        }

        setisTimeInArray(false)



        // disable save button once data is entered
}}, [timeHandle, schedules])

      const deleteAction=(cellValue)=>{

        //Todo: Post data from table to database
        console.log(cellValue);
        setisdeleted(true)
        setisTimeInArray(false)

        setTimeHandle([])
        toast.success("Selected date deleted ...", {position: 'top-right'})
        // enable save button once delete is clicked
      }

      const disableWeekends=(e)=>{


        if(e.target.checked){
            setisavailWeekend(false)
            toast.success("Weekend days removed ", {position: 'top-right'})
        }else{
            setisavailWeekend(true)
            toast.success("Weekend days added ", {position: 'top-right'})
        }

      }

      const styleOnClick=(cellValue)=>{


        if (timeHandle.includes(cellValue)){
            setisTimeInArray(true)
            setexistsMessage("You've already picked that time")

            setTimeHandle(timeHandle)

        }else{
            setisTimeInArray(false)
            setTimeHandle([...timeHandle, cellValue])
    }

    }



      return(
        <div className="admin-scheduler-page mt-5">
          < Toaster />
          <h3 className="font-bold font-header text-xl mb-3 mt-2">Set your available days</h3>
           <p className="font-bold text-md mt-1 font-text tracking-wide">Not available on weekends?
               <input className="checkAvail mx-3" onClick={disableWeekends} type="checkbox" id="weekends-available" name="Something"></input>
           </p>
           <p className="inArray-message">{isTimeInArray? existsMessage:""}</p>
            <div className="time-button-container" id="time-button-container">
                <h4 className="mt-2 font-bold text-md font-header mt-6"> Selected times </h4>
                {timeHandle.map((x, index)=>
                <button className="time-buttons" key={index}>{x}</button>)}

            </div>


            <div className="message-container">



                {
                !isTime? <div className="error-message">{timeMessage}</div>:

                isPosted ? <div className="spin error-message success-message"></div>:
                Response.status===0?
                <div className="error-message">
                    <strong>{Response.message}</strong>
                </div>:
                Response.status===1?
                <div className="success-message">
                   <strong>Saved {Response.message}fully</strong>
                </div>: ""}

            </div>

                <table id="cal-table" className="cal-table" key={"table"}>
                <thead>
                         <tr>
                              <th key={1}>Days</th>
                              <th key={2}>Date</th>
                              <th key={3}>Times</th>
                              <th key={4}>Action</th>
                              <th key={5}></th>
                         </tr>
                </thead>
                <tbody className="t-body" key={"body"}>

                {/* Individual: 1hr,  Couple: 1.30hr, Family: 2hrs*/}

                {monthCal.map((x, index)=> {return(
                <tr key={index+1}>
                    <td id="names" className="day-name" key={"days"}>{x.DaysName}</td>
                    <td key={"dates"} className="dates" ref={dateRef}>{x.date}</td>
                    <td key={"times"} className="time-buts">
                        {x.times.map((x, index)=>
                        <button key={index} data-target={x} ref={timesRef} onClick={()=>styleOnClick(x)}
                         id="time-buttons" className="time-buttons">{x}
                        </button>
                        )}
                    </td>

                    {!isavailWeekend && (x.DaysName==="Saturday" || x.DaysName==="Sunday")?
                        <td className="save-tab"><button key={index} className="save-button disabled" disabled onClick={()=>saveAction(x.date)}>Save</button></td>:
                        isPosted && x.DaysName?
                        <td className="save-tab"><button key={index} className="save-button disabled" disabled onClick={()=>saveAction(x.date)}>Save</button></td>:
                        <td className="save-tab"><button key={index} className="save-button" onClick={()=>saveAction(x.date)}>Save</button></td>
                    }

                    {!isavailWeekend && (x.DaysName==="Saturday" || x.DaysName==="Sunday")?
                        <td className="save-tab"><button key={index} className="save-button disabled" disabled onClick={()=>deleteAction(x.date)}>Delete</button></td>:
                        <td className="save-tab"><button key={index} className="save-button delete-button" id="save-button delete" onClick={()=>deleteAction(x.date)}>Delete</button></td>
                    }



                </tr>


                    )}
                )
                }

                </tbody>
            </table>


        </div>
      )

}

export default StaffSchedule
