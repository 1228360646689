import React, {useState, useEffect  }  from 'react';
import { useHistory, NavLink } from 'react-router-dom';

function AdminScheduleHistory()
{
     const [appointmentHstory, setAppointmentHstory] = useState(JSON.parse(sessionStorage.getItem('appointments')) ?? [])

     return (
          <div className="admin-scheduler-page mt-10">
               <h2 className="font-bold font-header text-xl mb-5">All schedules</h2>
               <table>
                    <thead>
                         <tr>
                              <th>Patient's name</th>
                              <th>Therapy type</th>
                              <th>Status</th>
                              <th>Time</th>
                              <th>Day</th>
                         </tr>
                    </thead>
                    { appointmentHstory.length > 0 ?
                         <tbody className="text-md font-text">
                              {appointmentHstory.map((appointment) => (
                                   <tr key={appointment.password}>
                                        <td>{appointment.client_name}</td>
                                        <td>{appointment.purpose}</td>
                                        <td>{appointment.state}</td>
                                        <td>{appointment.time}</td>
                                        <td>{appointment.meetingTime}</td>
                                   </tr>))
                              }
                         </tbody> :
                         <tbody>
                              <tr>
                                   <td colSpan="4" className="text-center font-bold text-md">
                                        You do not have an appointment scheduled yet, confirm you scheduled your available dates
                                   </td>
                              </tr>
                         </tbody>
                    }
               </table>
          </div>
     );
}

export default AdminScheduleHistory;
