import React, { useRef, useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import Spinner from './../components/spinner';
import Footer from './../components/footer';
import {loadUserTherapySessionInformation} from './../fetch-data/my-api';
import toast, { Toaster } from 'react-hot-toast';

function LoginPage() {
  const emailRef = useRef();
  const passwordRef = useRef();

  const [email, changeEmail] = useState('')
  const [password, changePassword] = useState('')

  const redirect = useHistory();

  const [isLoaded, setisLoaded] = useState(false)
  const [text_type, setTextType] = useState('password')
  const [toggleTextType, settoggleTextType] = useState(false)

  const [resstatus, setresStatus] = useState('')

  const [userToken, setuserToken] = useState("")
  const [first_name, setFirstName] = useState(localStorage.getItem("first_name"))
  const [isnoError, setisnoError] = useState(true)
  const [Message, setMessage] = useState('')

useEffect( () => {
     let button = document.querySelector('.login-submit-button')
     if(email.length > 12 && password.length > 4)
     {
          button.removeAttribute('disabled')
     }else {
          button.setAttribute('disabled', true)
     }
}, [email, password])

  const notify = e => {
       e.preventDefault();
       const controller = new AbortController();
       // toast("This is how it goes!!", {duration: 5000,  position: 'top-right'})
       const toastId = toast.loading('Loading...', {position: 'top-right'});
       let button = document.querySelector('.login-submit-button')
       button.setAttribute('disabled', true)
       setisLoaded(true)

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: emailRef.current.value,
        password: passwordRef.current.value,
      });

      var requestOptions = {
        method: "POST",
        signal: controller.signal,
        headers: myHeaders,
        body: raw,
      };

       fetch("https://megapsyche-omisolaidowu.b4a.run/api/login", requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json();
          }
        })
        .then(result => {
              setisLoaded(false)
              setuserToken(result.access_token)

              if (result.status===0){
                 setisLoaded(false)
                 toast.error(result.message, {
                    id: toastId,
                  });
                 setMessage(result.message)
            }else{
                 toast.success('Login succesful', {
                    id: toastId,
                  });
                 let retrieveData = loadUserTherapySessionInformation(emailRef.current.value, result.access_token).then(
                      (data) => {
                           sessionStorage.setItem("access_token", result.access_token)
                           sessionStorage.setItem("role", result.role)
                           sessionStorage.setItem("first_name", result.first_name)
                           sessionStorage.setItem("appointment_history", JSON.stringify(data))
                           toast('Redirecting you to your dashboard', {position: 'top-right'});
                           setTimeout(() => {
                                redirect.push("/user/dashboard")
                           }, 2000)
                      }
                 )
            }

          return result
     }).catch( (error) => {
          setisLoaded(false)
          button.setAttribute('disabled', false)
          toast.error('error', {
            id: toastId,
          });
          console.log("error", error)
     })

 }

  const togglePasswordVisibility = () => {
       if(text_type == 'password')
       {
            setTextType('text')
            settoggleTextType(true)
     }else{
          setTextType('password')
          settoggleTextType(false)
     }
 }

  return (
    <div className="bg-primary min-h-screen">
    { isLoaded ? <Spinner /> : ''}
    <Toaster/>
      <div className="">
        <div className="h-[400px] md:h-[300px] py-3 px-10 w-full bg-black/50 text-primary bg-login-hero bg-blend-multiply bg-no-repeat md:bg-cover bg-contain">
               <h1 className='mb-3 mt-20 font-header text-5xl font-header text-primary'>Welcome {first_name}!</h1>
               <p className="text-primary text-lg font-text">Sign in to continue</p>
        </div>

        <form onSubmit={notify} className="mx-10 py-5 pb-10 -mt-32 px-4 mb-12 shadow-lg rounded-lg bg-white md:w-1/4 md:mx-auto">
          <h3 className="font-header font-bold text-3xl text-secondary">Login</h3>
        {  resstatus !==1 ? <p className="errorMessage">{Message}</p>   : <p className="errorMessage">{Message}</p>  }
          <div className="mt-6 rounded-lg p-3 border border-gray-500 flex justify-start items-center gap-2 w-full">
             <svg className="w-9 h-8 md:w-7 md:h-6 text-secondary" data-slot="icon" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"></path></svg>
            <input placeholder="opeyemi@gmail.com" required type="email" id="username" ref={emailRef} onChange={ (e) => changeEmail(e.target.value)} className="bg-transparent px-4 text-md py-2 focus:outline-none focus:bg-transparent w-full"/>
          </div>
          <div className="mt-8 rounded-lg p-3 border border-gray-500 flex justify-start items-center gap-2 relative">
            <svg className="w-9 h-8 md:w-7 md:h-6 text-secondary" data-slot="icon" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"></path></svg>

            {
                 toggleTextType ? <svg data-slot="icon" onClick={togglePasswordVisibility} className="w-9 h-8 md:w-7 md:h-6 cursor-pointer text-secondary absolute right-3 top-5" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"></path></svg> : <svg onClick={togglePasswordVisibility} className="w-9 h-8 md:w-7 md:h-6 cursor-pointer text-secondary absolute right-3 top-5" data-slot="icon" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"></path><path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"></path></svg>
            }


            <input required type={text_type} id="password" ref={passwordRef} placeholder="****" onChange={ (e) => changePassword(e.target.value)} className="p-3 bg-transparent px-4 text-md py-2 focus:outline-none w-full focus:bg-transparent"/>
          </div>
          <div className="mt-10 flex justify-center ">
              <button className='px-12 py-3 rounded-lg disabled:bg-gray-400 disabled:text-gray-300 shadow-lg disabled:shadow-none bg-secondary text-primary login-submit-button cursor-pointer' disabled type="submit">Login</button>
          </div>
          <p className="text-center mt-3 text-secondary"> Forgot password? </p>

          <div className="flex justify-center items-center gap-4 my-3">
               <div className="h-1 w-1/3 bg-yellow-400"></div>
               <span>Or</span>
               <div className="h-1 w-1/3 bg-yellow-400"></div>
          </div>
          <p className="text-center text-secondary"><NavLink to="/register" className="anchorTag">Create an account</NavLink></p>
        </form>
      </div>
      <div className="sideContainerForLogin"></div>
      <Footer />
    </div>
  );
};

export default LoginPage;
