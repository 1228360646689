import React, {useCallback, useState, useEffect} from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import avater from './../public/avater.jpg';

function SideBar()
{
     const toggleNavigation = useState(false)
     const [role, setRole] = useState('user')

     useEffect( () => {
          setRole(sessionStorage.getItem('role'))
     }, [])

     const useToggleNavigation = useCallback( () => {
          let toggle = document.querySelector('.sideBarContainer')
          toggle.classList.toggle('openSideBar')
     })

     return (
          <div className="">
               <nav className="">
                    <div className="navigationToggle" onClick={useToggleNavigation}>
                         <div className="navigationIconContainer">
                              <span className="navigationIcon">&#x21c7;</span>
                         </div>
                    </div>
               </nav>
               <div className="sideBarContainer bg-primary md:bg-secondary pl-10 pr-6 fixed top-0 right-0 h-screen w-[75vw] z-20 translate-x-[300px] transition-transform md:px-5">
                    <div className="flex justify-center">
                         <img className="w-32 h-32 rounded-full mt-10 mb-8 md:w-40 md:h-40 md:mt-16 md:sticky md:top-0" alt='profile img' src={avater} />
                    </div>
                         <ul className="navigationLinksUL text-right text-md md:text-md md:text-left text-secondary md:text-primary">
                         <li className="flex justify-end items-center md:flex-row-reverse gap-4">
                              <NavLink to="/user/dashboard" className="anchorTag block">Home</NavLink>
                              <svg data-slot="icon"  className="w-5 h-5" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"></path></svg>
                         </li>
                         <li className="flex justify-end items-center gap-4 md:flex-row-reverse">
                              <NavLink to="/user/profile" className="anchorTag">Profile</NavLink>
                              <svg data-slot="icon" className="w-5 h-5" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"></path></svg>
                         </li>
                         <li className="flex justify-end items-center gap-4 md:flex-row-reverse">
                              <NavLink to="/user/book-appointment" className="anchorTag ">Book an appointment</NavLink>
                              <svg data-slot="icon" className=" w-5 h-5" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"></path></svg>
                         </li>
                         <li className="flex justify-end items-center gap-4 md:flex-row-reverse">
                              <NavLink to="/user/my-appointments" className="anchorTag">All appointments</NavLink>
                              <svg data-slot="icon" fill="none" className="w-5 h-5" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25"></path></svg>
                         </li>
                         <li className="flex justify-end items-center gap-4 md:flex-row-reverse">
                              <NavLink to="/user/faq" className="anchorTag">Frequently Asked Questions</NavLink>
                              <svg data-slot="icon" className="w-8 h-8" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"></path></svg>
                         </li>
                         {
                              (role !=  'user') ?
                              <li className="flex justify-end items-center gap-4 md:flex-row-reverse">
                                   <NavLink to="/admin/dashboard" className="anchorTag">Admin console</NavLink>
                                   <svg data-slot="icon" className="w-6 h-6" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round"  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"></path></svg>
                              </li> : ''
                         }
                         <li className="flex justify-end items-center gap-4 md:flex-row-reverse">
                              <NavLink to="/logout" className="anchorTag">Logout</NavLink>
                              <svg data-slot="icon" className="w-5 h-5" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path></svg>
                         </li>
                    </ul>
                    <div className="navigationToggleLeft " onClick={useToggleNavigation}>
                         <div className="navigationIconContainer">
                              <span className="navigationIcon">&#x21c9;</span>
                         </div>
                    </div>
               </div>
          </div>
     );
}

export default SideBar;
