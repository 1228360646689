import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';

function AboutPage()
{
     return (
          <h1>This is About page</h1>
     );
}

export default AboutPage;
