import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';

function Footer()
{
     return (
          <footer className="bg-black py-4 px-12 pb-20 md:pb-14 md:px-20">
               <p className="text-primary py-0 text-center">&copy; Copyright of Megapsyche</p>
               <div className="mt-4 flex justify-between md:justify-start md:gap-32">
                    <div className="grid gap-2 justify-start">
                         <p className="text-white/90 text-md">Pages</p>
                         <NavLink to="/" className="text-white/70 text-md">Home</NavLink>
                         <NavLink to="/contact" className="text-white/70 text-md">Contact</NavLink>
                         <NavLink to="/about" className="text-white/70 text-md">About us</NavLink>
                    </div>
                    <div className="grid gap-2 justify-start">
                         <p className="text-white/90 text-md">Forms</p>
                         <NavLink to="/login" className="text-white/70 text-md">Login</NavLink>
                         <NavLink to="/register" className="text-white/70 text-md">Register</NavLink>
                    </div>
               </div>
          </footer>
     );
}

export default Footer;
