import React, {useCallback, useState} from 'react';
import { useHistory, NavLink } from 'react-router-dom';


const AdminLayout = (Component) => ({ ...props }) =>  {
     const user = JSON.parse(sessionStorage.getItem('user'))

     return (
          <main className="admin-layout-dashboard-container">
               <nav className="sidebar-for-admin text-lg">
                    <h1 className="admin-nav-h1">Psyche Mega Therapy</h1>
                    <NavLink className="ul-major-click" to="/admin/set-available-days">
                         <svg data-slot="icon" fill="currentColor" viewBox="0 0 20 20" width="80px" height="90px" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M5.25 12a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H6a.75.75 0 0 1-.75-.75V12ZM6 13.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V14a.75.75 0 0 0-.75-.75H6ZM7.25 12a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H8a.75.75 0 0 1-.75-.75V12ZM8 13.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V14a.75.75 0 0 0-.75-.75H8ZM9.25 10a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H10a.75.75 0 0 1-.75-.75V10ZM10 11.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V12a.75.75 0 0 0-.75-.75H10ZM9.25 14a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H10a.75.75 0 0 1-.75-.75V14ZM12 9.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V10a.75.75 0 0 0-.75-.75H12ZM11.25 12a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H12a.75.75 0 0 1-.75-.75V12ZM12 13.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V14a.75.75 0 0 0-.75-.75H12ZM13.25 10a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H14a.75.75 0 0 1-.75-.75V10ZM14 11.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V12a.75.75 0 0 0-.75-.75H14Z"></path><path clipRule="evenodd" fillRule="evenodd" d="M5.75 2a.75.75 0 0 1 .75.75V4h7V2.75a.75.75 0 0 1 1.5 0V4h.25A2.75 2.75 0 0 1 18 6.75v8.5A2.75 2.75 0 0 1 15.25 18H4.75A2.75 2.75 0 0 1 2 15.25v-8.5A2.75 2.75 0 0 1 4.75 4H5V2.75A.75.75 0 0 1 5.75 2Zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75Z"></path></svg>
                         <div className="py-2 text-sm font-bold font-text tracking-wide">
                              <h4>Set available days</h4>
                         </div>
                    </NavLink>
                    <ul>
                         <li>
                              <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" id="house-user" className="dashboard-icon"><path fill="#FFFFFF" d="M21.8,10.6l-2-1.8c0,0,0,0,0,0l-7.5-6.7c-0.2-0.2-0.5-0.2-0.7,0L4.2,8.8c0,0,0,0,0,0l-2,1.8c0,0,0,0,0,0c-0.2,0.2-0.2,0.5,0,0.7c0.2,0.2,0.5,0.2,0.7,0L4,10.3v11.2c0,0,0,0,0,0C4,21.8,4.3,22,4.5,22h14.9c0,0,0,0,0,0c0.3,0,0.5-0.2,0.5-0.5V10.3l1.2,1.1c0.1,0.1,0.2,0.1,0.3,0.1v0c0.1,0,0.3-0.1,0.4-0.2C22.1,11.1,22,10.8,21.8,10.6z M8.1,21c0.4-1.6,1.8-2.8,3.4-3c2-0.2,3.8,1.1,4.3,3H8.1z M9.5,14.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5S9.5,15.9,9.5,14.5z M19,21h-2.1c-0.3-1.6-1.4-3-2.9-3.6c0.9-0.6,1.6-1.7,1.6-2.9c0-1.9-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5c0,1.2,0.6,2.3,1.6,2.9C8.6,18,7.5,19.3,7.1,21H5V9.4l7-6.2l7,6.2V21z"></path></svg>
                              <NavLink to="/admin/dashboard" className="anchorTag">Home</NavLink>
                         </li>

                         <li>
                              <svg data-slot="icon" className="dashboard-icon" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"></path></svg>
                              <NavLink to="/admin/profile" className="anchorTag">Profile</NavLink>
                         </li>

                         <li>
                              <svg xmlns="http://www.w3.org/2000/svg" className="dashboard-icon" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" id="calender"><path fill="#FBFBFB" d="M19.5,4h-3V2.5C16.5,2.223877,16.276123,2,16,2s-0.5,0.223877-0.5,0.5V4h-7V2.5C8.5,2.223877,8.276123,2,8,2S7.5,2.223877,7.5,2.5V4H4.4995117c-1.380127,0.0014648-2.4985352,1.119873-2.5,2.5v13c0.0014648,1.380127,1.119873,2.4985352,2.5,2.5H19.5c1.380188-0.0012817,2.4987183-1.119812,2.5-2.5v-13C21.9987183,5.119812,20.880188,4.0012817,19.5,4z M21,19.5c-0.0009155,0.828064-0.671936,1.4990845-1.5,1.5H4.4995117c-0.828064-0.0009155-1.4990845-0.671936-1.5-1.5V11H21V19.5z M21,10H2.9995117V6.5c0.0009155-0.828064,0.671936-1.4990845,1.5-1.5H7.5v1.5c0,0.0001831,0,0.0003662,0,0.0006104C7.5001831,6.7765503,7.723999,7.0001831,8,7c0.0001831,0,0.0003662,0,0.0006104,0C8.2765503,6.9998169,8.5001831,6.776001,8.5,6.5V5h7v1.5c0,0.0001831,0,0.0003662,0,0.0006104C15.5001831,6.7765503,15.723999,7.0001831,16,7c0.0001831,0,0.0003662,0,0.0006104,0C16.2765503,6.9998169,16.5001831,6.776001,16.5,6.5V5h3c0.828064,0.0009155,1.4990845,0.671936,1.5,1.5V10z"></path></svg>
                              <NavLink to="/admin/set-available-days" className="anchorTag">Appointment availability</NavLink>
                         </li>
                         <li>
                              <svg xmlns="http://www.w3.org/2000/svg" className="dashboard-icon" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" id="bookmark"><path fill="#FFF9F9" d="M16,2H8C6.3438721,2.0018311,5.0018311,3.3438721,5,5v16.5c0.0001221,0.0870972,0.0230103,0.1727295,0.0663452,0.248291C5.2039795,21.9882202,5.5100708,22.071167,5.75,21.9335938L12,18.3339844l6.25,3.5996094C18.3262329,21.9768677,18.4123535,21.9997559,18.5,22c0.2759399-0.0004883,0.4995117-0.2240601,0.5-0.5V5C18.9981689,3.3438721,17.6561279,2.0018311,16,2z M18,20.6347656l-5.75-3.3115234c-0.0762329-0.0432739-0.1623535-0.0661621-0.25-0.0664062c-0.0876465,0.0002441-0.1737671,0.0231323-0.25,0.0664062L6,20.6347656V5c0.0014038-1.1040039,0.8959961-1.9985962,2-2h8c1.1040039,0.0014038,1.9985962,0.8959961,2,2V20.6347656z"></path></svg>
                              <NavLink to="/admin/my-appointments" className="anchorTag">All appointments</NavLink>
                         </li>
                         <li>
                              <svg data-slot="icon" className="dashboard-icon" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"></path></svg>
                              <NavLink to="/user/dashboard" className="anchorTag">User's dashboard</NavLink>
                         </li>
                         <li>
                              <svg xmlns="http://www.w3.org/2000/svg" className="dashboard-icon" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" id="signout"><path fill="#FFFFFF" d="M4,12c0,0.276123,0.223877,0.5,0.5,0.5h8.7930298l-2.6465454,2.6464844c-0.09375,0.09375-0.1464233,0.2208862-0.1464233,0.3534546C10.5,15.776062,10.723877,15.999939,11,16c0.1326294,0.0001221,0.2598267-0.0525513,0.3534546-0.1464844l3.5-3.5c0.000061-0.000061,0.0001221-0.000061,0.0001831-0.0001221c0.1951294-0.1952515,0.1950684-0.5117188-0.0001831-0.7068481l-3.5-3.5c-0.1937256-0.1871338-0.5009155-0.1871338-0.6947021,0c-0.1986084,0.1918335-0.2041016,0.5083618-0.0122681,0.7069702L13.2930298,11.5H4.5C4.223877,11.5,4,11.723877,4,12z M17.5,2h-11C5.119812,2.0012817,4.0012817,3.119812,4,4.5v4C4,8.776123,4.223877,9,4.5,9S5,8.776123,5,8.5v-4C5.0009155,3.671936,5.671936,3.0009155,6.5,3h11c0.828064,0.0009155,1.4990845,0.671936,1.5,1.5v15c-0.0009155,0.828064-0.671936,1.4990845-1.5,1.5h-11c-0.828064-0.0009155-1.4990845-0.671936-1.5-1.5v-4C5,15.223877,4.776123,15,4.5,15S4,15.223877,4,15.5v4c0.0012817,1.380188,1.119812,2.4987183,2.5,2.5h11c1.380188-0.0012817,2.4987183-1.119812,2.5-2.5v-15C19.9987183,3.119812,18.880188,2.0012817,17.5,2z"></path></svg>
                              <NavLink to="/logout" className="anchorTag">Logout</NavLink>
                         </li>
                    </ul>
               </nav>
               <section>
                    <header>
                         <div>
                              <h1>Hello {user.first_name}</h1>
                              <small>{new Date().toDateString() }</small>
                         </div>
                    </header>
                    <Component {...props} />
               </section>
          </main>
     );
}

export default AdminLayout;
