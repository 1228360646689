import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import detele_token_db from '../fetch-data/token.delete.db';
import toast, { Toaster } from 'react-hot-toast';

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    const access_token = sessionStorage.getItem("access_token")
    detele_token_db(access_token)
    sessionStorage.clear();
    toast.success('Successfully logged out of your account');
    setTimeout(() => {
         toast('Redirecting you to  the homepage');
         history.push("/login")
    }, 2000)
  }, [history]);
  return null;
};

export default Logout;
