import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';

function ContactPage()
{
     return (
          <h1>This is Contact page</h1>
     );
}

export default ContactPage;
