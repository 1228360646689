import React, {useEffect, useState} from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import avater from './../public/avater.jpg';

function ProfilePage()
{
     const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')))
     return (
          <div className="min-h-screen bg-primary overflow-hidden">
               <header className="flex justify-start items-center font-bold py-6">
                    <NavLink to="/user/dashboard" className="anchorTag text-4xl md:hidden">&#x2190;</NavLink>
                    <div className="w-full text-center ">
                         <h1 className="text-xl font-heading tracking-wide">{user.first_name}'s profile</h1>
                    </div>
               </header>

               <div className="md:hidden absolute left-0 top-36 flex justify-center z-20 w-full md:mx-auto md:relative border border-black">
                    <img title="User avater" alt="user's avater" src={avater} className="w-28 h-28 rounded-full"/>
               </div>

               <div className="bg-tertiary rotate-[-10deg] z-10 absolute mt-2 w-[2000px] -ml-72 h-56 md:hidden">

               </div>
               <div className="z-20 absolute top-64 mt-4 -ml-3 text-center w-full text-primary font-text tracking-wide md:text-secondary md:static md:ml-0 md:mt-0 md:bg-white md:shadow-2xl md:mx-auto md:top-0 md:px-10 md:py-10">
                    <img title="User avater" alt="user's avater" src={avater} className="w-28 h-28 rounded-full hidden md:block mx-auto"/>
                    <p className="font-bold text-xl">{user.first_name} {user.last_name}</p>
                    <p className="">{user.email}</p>
               </div>
          </div>
     );
}

export default ProfilePage;
