import React , { useState, useEffect  }  from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { therapistAppointment } from './../fetch-data/my-api';
import Spinner from './../components/spinner';
import toast, { Toaster } from 'react-hot-toast';


function AdminHome()
{
     const [loading, setLoading] = useState(true)
     const [information, setInformation] = useState([])
     const [statistics, setStatistics] = useState({})
     const [appointmentHstory, setAppointmentHstory] = useState([])
     const [upcomingAppointmentHstory, setUpcomingAppointmentHstory] = useState([])

     const [user] = useState(JSON.parse(sessionStorage.getItem("user")))

     useEffect( ( ) => {
          if(loading)
          {
               const toastId = toast.loading('Loading your information', {position: 'top-right'})
               therapistAppointment(user.email).then( (response) => {
                    if(response.length > 0)
                    {
                         let hours = response.reduce((total, current) => current.duration + total, 0) / 60;
                         let patients = []
                         let upcomingAppointments = []
                         let totalAppointments = 0
                         do {
                              if( !patients.includes(response[totalAppointments]['client_email'], 0) )
                              {
                                   patients.push(response[totalAppointments]['client_email'])
                              }
                              if(response[totalAppointments]['state'] == 'Upcoming')
                              {
                                   upcomingAppointments.push(response[totalAppointments])
                              }
                              totalAppointments++
                         } while (totalAppointments < response.length);
                         let stats = { hours_completed_monthly: hours, hours_completed_yearly: hours, monthly_patient: patients.length, yearly_patient: patients.length}
                         setStatistics(stats)
                         sessionStorage.setItem('appointments', JSON.stringify(response))
                         if (response.length <= 3) {
                             setAppointmentHstory(response)
                         }
                         if(upcomingAppointments.length <= 3)
                         {
                              setUpcomingAppointmentHstory(upcomingAppointments)
                         }
                         // Otherwise, slice the array to get the first 3 elements
                         setAppointmentHstory(response.slice(0, 3));
                         setUpcomingAppointmentHstory(upcomingAppointments.slice(0, 3))
                    }
                    setInformation(response)
                    toast.success('Data succesfully retrieved', {position: 'top-right', id: toastId})
                    setLoading(false)
                    // console.log(response)
               })
               .catch( (error) => {
                    console.log(error)
               })
          }
     }, [loading])

     return (
          <div className="adminpage-section-beside-nav font-text tracking-wider text-md text-black">
               < Toaster />
               { loading ? <Spinner /> : ''}
               <section className="flex-start margin-top-20">
                    <div>
                         <h4 className="font-bold font-header text-lg mb-3">Stats for the month</h4>
                         <div className="flex-start">
                              <div className="admin-stat-container grid-center">
                                     <svg xmlns="http://www.w3.org/2000/svg" className="admin-stat-icon" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" id="calender"><path fill="#013D8C" d="M19.5,4h-3V2.5C16.5,2.223877,16.276123,2,16,2s-0.5,0.223877-0.5,0.5V4h-7V2.5C8.5,2.223877,8.276123,2,8,2S7.5,2.223877,7.5,2.5V4H4.4995117c-1.380127,0.0014648-2.4985352,1.119873-2.5,2.5v13c0.0014648,1.380127,1.119873,2.4985352,2.5,2.5H19.5c1.380188-0.0012817,2.4987183-1.119812,2.5-2.5v-13C21.9987183,5.119812,20.880188,4.0012817,19.5,4z M21,19.5c-0.0009155,0.828064-0.671936,1.4990845-1.5,1.5H4.4995117c-0.828064-0.0009155-1.4990845-0.671936-1.5-1.5V11H21V19.5z M21,10H2.9995117V6.5c0.0009155-0.828064,0.671936-1.4990845,1.5-1.5H7.5v1.5c0,0.0001831,0,0.0003662,0,0.0006104C7.5001831,6.7765503,7.723999,7.0001831,8,7c0.0001831,0,0.0003662,0,0.0006104,0C8.2765503,6.9998169,8.5001831,6.776001,8.5,6.5V5h7v1.5c0,0.0001831,0,0.0003662,0,0.0006104C15.5001831,6.7765503,15.723999,7.0001831,16,7c0.0001831,0,0.0003662,0,0.0006104,0C16.2765503,6.9998169,16.5001831,6.776001,16.5,6.5V5h3c0.828064,0.0009155,1.4990845,0.671936,1.5,1.5V10z"></path></svg>
                                   <h2 className="font-bold font-header text-2xl">{loading ? '....' : '' }</h2>
                                   { !loading ?
                                        <h2 className="font-bold font-header text-2xl">{ information !== null ? information.length : '0' }</h2> : ''
                                   }
                                   <p>Appointments</p>
                              </div>
                              <div className="admin-stat-container grid-center">
                                 <svg data-slot="icon" className="admin-stat-icon" fill="none" strokeWidth="1.5" stroke="green" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"></path></svg>
                                   <h2 className="font-bold font-header text-2xl">{loading ? '....' : '' }</h2>
                                   { !loading ?
                                        <h2 className="font-bold font-header text-2xl">{ information.length  > 0  ? statistics.monthly_patient : '0' }</h2> : ''
                                   }
                                   <p>Patients seen</p>
                              </div>
                              <div className="admin-stat-container grid-center">
                                   <svg xmlns="http://www.w3.org/2000/svg" className="admin-stat-icon" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" id="clock-five"><path fill="#8C1616" d="M12.5,11.8740234V7c0-0.276123-0.223877-0.5-0.5-0.5S11.5,6.723877,11.5,7v5c0.0001221,0.0824585,0.0206299,0.1636353,0.0595703,0.2363281l1.5,2.7988281c0.0869751,0.1623535,0.2562256,0.2637329,0.4404297,0.2636719c0.0825195,0.0003052,0.1638184-0.0202026,0.2363281-0.0595703c0.0002441-0.0001221,0.0004272-0.0002441,0.0006714-0.0003662c0.2429199-0.1306152,0.3340454-0.4334717,0.2034302-0.6763916L12.5,11.8740234z M12,2C6.4771729,2,2,6.4771729,2,12s4.4771729,10,10,10c5.5201416-0.0064697,9.9935303-4.4798584,10-10C22,6.4771729,17.5228271,2,12,2z M12,21c-4.9705811,0-9-4.0294189-9-9s4.0294189-9,9-9c4.9683228,0.0054321,8.9945679,4.0316772,9,9C21,16.9705811,16.9705811,21,12,21z"></path></svg>
                                   <h2 className="font-bold font-header text-2xl">{loading ? '....' : '' }</h2>
                                   { !loading ?
                                        <h2 className="font-bold font-header text-2xl">{ information.length > 0 ? statistics.hours_completed_monthly : '0' }</h2> : ''
                                   }
                                   <p>Hours spent</p>
                              </div>
                         </div>
                    </div>
                    <div>
                         <h4 className="font-bold font-header text-lg mb-3">Stats for the Year</h4>
                         <div className="flex-start">
                              <div className="admin-stat-container grid-center">
                                   <svg xmlns="http://www.w3.org/2000/svg" className="admin-stat-icon" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" id="calender"><path fill="#013D8C" d="M19.5,4h-3V2.5C16.5,2.223877,16.276123,2,16,2s-0.5,0.223877-0.5,0.5V4h-7V2.5C8.5,2.223877,8.276123,2,8,2S7.5,2.223877,7.5,2.5V4H4.4995117c-1.380127,0.0014648-2.4985352,1.119873-2.5,2.5v13c0.0014648,1.380127,1.119873,2.4985352,2.5,2.5H19.5c1.380188-0.0012817,2.4987183-1.119812,2.5-2.5v-13C21.9987183,5.119812,20.880188,4.0012817,19.5,4z M21,19.5c-0.0009155,0.828064-0.671936,1.4990845-1.5,1.5H4.4995117c-0.828064-0.0009155-1.4990845-0.671936-1.5-1.5V11H21V19.5z M21,10H2.9995117V6.5c0.0009155-0.828064,0.671936-1.4990845,1.5-1.5H7.5v1.5c0,0.0001831,0,0.0003662,0,0.0006104C7.5001831,6.7765503,7.723999,7.0001831,8,7c0.0001831,0,0.0003662,0,0.0006104,0C8.2765503,6.9998169,8.5001831,6.776001,8.5,6.5V5h7v1.5c0,0.0001831,0,0.0003662,0,0.0006104C15.5001831,6.7765503,15.723999,7.0001831,16,7c0.0001831,0,0.0003662,0,0.0006104,0C16.2765503,6.9998169,16.5001831,6.776001,16.5,6.5V5h3c0.828064,0.0009155,1.4990845,0.671936,1.5,1.5V10z"></path></svg>
                                   <h2 className="font-bold font-header text-2xl">{loading ? '....' : '' }</h2>
                                   { !loading ?
                                        <h2 className="font-bold font-header text-2xl">{ information !== null ? information.length : '0' }</h2> : ''
                                   }
                                   <p>Appointments</p>
                              </div>
                              <div className="admin-stat-container grid-center">
                                    <svg data-slot="icon" className="admin-stat-icon" fill="none" strokeWidth="1.5" stroke="green" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"></path></svg>
                                   <h2 className="font-bold font-header text-2xl">{loading ? '....' : '' }</h2>
                                   { !loading ?
                                        <h2 className="font-bold font-header text-2xl">{ information.length > 0 ? statistics.monthly_patient : '0' }</h2> : ''
                                   }
                                   <p>Patients seen</p>
                              </div>
                              <div className="admin-stat-container grid-center">
                                     <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" className="admin-stat-icon" viewBox="0 0 24 24" id="clock-five"><path fill="#8C1616" d="M12.5,11.8740234V7c0-0.276123-0.223877-0.5-0.5-0.5S11.5,6.723877,11.5,7v5c0.0001221,0.0824585,0.0206299,0.1636353,0.0595703,0.2363281l1.5,2.7988281c0.0869751,0.1623535,0.2562256,0.2637329,0.4404297,0.2636719c0.0825195,0.0003052,0.1638184-0.0202026,0.2363281-0.0595703c0.0002441-0.0001221,0.0004272-0.0002441,0.0006714-0.0003662c0.2429199-0.1306152,0.3340454-0.4334717,0.2034302-0.6763916L12.5,11.8740234z M12,2C6.4771729,2,2,6.4771729,2,12s4.4771729,10,10,10c5.5201416-0.0064697,9.9935303-4.4798584,10-10C22,6.4771729,17.5228271,2,12,2z M12,21c-4.9705811,0-9-4.0294189-9-9s4.0294189-9,9-9c4.9683228,0.0054321,8.9945679,4.0316772,9,9C21,16.9705811,16.9705811,21,12,21z"></path></svg>
                                   <h2 className="font-bold font-header text-2xl">{loading ? '....' : '' }</h2>
                                   { !loading ?
                                        <h2 className="font-bold font-header text-2xl">{ information.length > 0 ? statistics.hours_completed_yearly : '0' }</h2> : ''
                                   }
                                   <p>Hours spent</p>
                              </div>
                         </div>
                    </div>
               </section>

               <div className="flex-start-align-top mt-32">
                    <div className="admin-home-history-wrapper">
                         <div className="flex-between mb-3">
                              <h4 className="font-bold font-header text-lg">Upcoming meetings</h4>
                              { !loading && information.length > 0 ? <p>View all <span className="arrow-entity">&#8674;</span> </p> : ''}
                         </div>
                         <div className="admin-home-history-container">
                              {!loading ?
                                   <div>
                                        { upcomingAppointmentHstory.length > 0 ?
                                             <div>
                                                  {upcomingAppointmentHstory.map((appointment) => (
                                                       <div className="admin-upcoming-schedule" key={appointment.meeting_url}>
                                                            <div className="flex-between">
                                                                 <h2>{appointment.purpose}</h2>
                                                                 <p>{appointment.time}</p>
                                                            </div>
                                                            <div className="flex-between">
                                                                 <p>{appointment.client_name}</p>
                                                                 <p>{appointment.meetingTime}</p>
                                                            </div>
                                                       </div>))
                                                  }
                                             </div> :
                                             <div>
                                                  <p className="py-5 text-center text-md font-text">You do not have an upcoming schedules yet. Kindly confirm that your available dates are provided.</p>
                                             </div>

                                        }
                                   </div> :
                                   <div>
                                        <p className="font-bold text-center text-md font-text">Loading all upcoming schedules</p>
                                   </div>
                              }
                         </div>
                    </div>
                    <div className="admin-home-history-wrapper">
                         <div className="flex-between mb-3">
                              <h4 className="font-bold font-header text-lg">Schedule history</h4>
                              { !loading && information.length > 0 ? <p>View all <span className="arrow-entity">&#8674;</span> </p> : ''}
                         </div>
                         <div className="admin-home-history-container">
                              <table>
                                   <thead>
                                        <tr>
                                             <th>Patient's name</th>
                                             <th>Therapy type</th>
                                             <th>Time</th>
                                             <th>Day</th>
                                        </tr>
                                   </thead>
                                   { appointmentHstory.length > 0 ?
                                        <tbody className="text-md font-text">
                                             {appointmentHstory.map((appointment) => (
                                                  <tr key={appointment.password}>
                                                       <td>{appointment.client_name}</td>
                                                       <td>{appointment.purpose}</td>
                                                       <td>{appointment.time}</td>
                                                       <td>{appointment.meetingTime}</td>
                                                  </tr>))
                                             }
                                        </tbody> :
                                        <tbody>
                                             <tr>
                                                  <td colSpan="4" className="text-center font-text text-md">
                                                       You do not have an appointment scheduled yet, confirm you scheduled your available dates
                                                  </td>
                                             </tr>
                                        </tbody>
                                   }
                              </table>
                         </div>
                    </div>
               </div>
          </div>
     );
}

export default AdminHome;
