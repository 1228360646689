import React, {useState, useCallback, useEffect} from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import hero from './../public/profile-hero.jpg';
import avater from './../public/avater.jpg';
import { getAllTherapists, updateBio } from "./../fetch-data/my-api";
import toast, { Toaster } from 'react-hot-toast';

function AdminProfile()
{
     const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
     const [hasBio, setHasBio] = useState(true);
     const [initiateSubmission, setInitiateSubmission ] = useState(false)

     const [bioInput, setBioInput] = useState('')
     const [experienceInput, setExperienceInput] = useState('')
     const [certificationInput, setCertificationInput] = useState('')

     const [therapist, setTherapist] = useState(JSON.parse(sessionStorage.getItem("therapist_bio")))
     useEffect( () => {
          let all_therapist = getAllTherapists().then( (therapists) => {
               if (therapists.data.length > 0) {
                    let currentTherapist = therapists.data.find((therapist, index) =>{
                         return therapist.email == user.email
                    })
                    if(currentTherapist === undefined)
                    {
                         setHasBio(false)
                    }else{
                         setTherapist(currentTherapist)
                         sessionStorage.setItem("therapist_bio", JSON.stringify(currentTherapist))
                    }

               }
          }).catch( (error) => {
               console.log(error)
          })
     }, [])

     const editBioFn = (event) => {
          setBioInput(event.target.value);
     }

     const submitBio = (event) => {
          event.preventDefault()
          const toastId = toast.loading("Updating your profile ... ", {position: 'top-right'})
          setInitiateSubmission(true)
          updateBio(bioInput, certificationInput, experienceInput).then( (response) =>{
               toast.success("Your profile has been updated succesfully!", {position: 'top-right', id: toastId})
               setHasBio(true)
          }).catch((error) => {
               toast.error(error, {position: 'top-right', id: toastId})
          })
     }

     const updateExperience = (event) => {
          setExperienceInput(event.target.value)
     }

     const updateCertification = (event) => {
          setCertificationInput(event.target.value)
     }

     return (
          <div className="admin-scheduler-page">
               < Toaster />
               <form onSubmit={submitBio}>
                    <div className="admin-profile-hero">
                         <img src={hero} className="admin-profile-hero-image" alt="" title="Admin profile Hero Section" />
                         <div className="admin-profile-hero-footer">
                              <div className="admin-profile-footer-content flex-start">
                                   <img src={avater} className="admin-profile-picture" alt="" title="Admin profile Hero Section" />
                                   <div>
                                        <p className="admin-profile-quote">Live, Love, Laugh</p>
                                        <p className="admin-profile-summary">Therapist ethics prioritize client autonomy, well-being, confidentiality, fairness, and clear boundaries in a respectful and competent relationship.</p>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className=" profile-content-wrapper">
                         <div className="flex-between-align-top">
                              <span>
                                   <h4>Years of experience</h4>
                                   { !hasBio ?
                                        <input type="text" value={experienceInput} onChange={updateExperience} required minLength="1"/> :
                                        <input type="text" readOnly defaultValue={therapist.experience}  />
                                   }
                              </span>
                              <span><h4>Name</h4>
                              <input readOnly defaultValue={ user.first_name + ' ' + user.last_name} type="text"/></span>
                         </div>
                         <div className="flex-between-align-top">
                              <span><h4>Certifications</h4>
                              { !hasBio ?
                                   <input type="text" value={certificationInput} onChange={updateCertification} required minLength="5"/> :
                                   <input type="text" readOnly   defaultValue={therapist.certification} />
                              }
                              </span>


                              <span><h4>Email</h4>
                              <input readOnly defaultValue={ user.email } type="text" /></span>
                         </div>
                         <div>
                              <h4>Bio</h4>
                              { !hasBio ?
                                   <textarea value={bioInput} onChange={editBioFn} type="text" className="bio-input py-2" required minLength="100"> {bioInput} </textarea> :
                                   <textarea type="text" readOnly defaultValue={therapist.bio} className="bio-input"></textarea>
                              }

                         </div>
                         { !hasBio ?
                              <div className="flex justify-center w-full my-3" >
                                   <button className="rounded-lg shadow-lg hover:shadow-2xl bg-secondary px-16 py-3 text-primary font-text disabled:bg-primary disabled:text-secondary" type="submit" disabled={initiateSubmission} >Update Bio</button>
                              </div> : ''
                         }
                    </div>
               </form>
          </div>
     );
}

export default AdminProfile;
