import React from 'react';
import { NavLink } from 'react-router-dom';

function FaqPage()
{
     return (
          <div className="">
               <header className="bg-secondary py-5 px-3 text-primary absolute top-0 right-0 w-full">
                    <NavLink to="/user/dashboard" className="font-bold text-2xl">&#x2190;</NavLink>
                    <h1 className="text-center text-lg font-bold font-header">Frequently Asked Questions</h1>
               </header>
               <section className="w-11/12 pt-32 font-text tracking-wide pb-20">
                    <div className="font-bold text-center">
                         <p>Here's a compilation of commonly asked questions that we hope would be helpful to you.</p>
                    </div>

                    <div className="mt-6 text-md">
                         <h4 className="font-bold font-header text-xl mb-2">Payment</h4>
                         <details className="mb-3 shadow-md border border-gray-300">
                              <summary className="px-3 list-none shadow-md font-header py-2">Does Psychelapcare accept  insurance?</summary>
                              <p className="px-3 py-2 ">Psychelapcare does not accept insurance but payment can be made via  bank transfer. In case of transfer, you are to send your evidence of payment to the email Psychelapcare@gmail.com or call this number (07060681466) for confirmation.</p>
                         </details>
                         <details className="mb-3 shadow-md border border-gray-300">
                              <summary className="px-3 list-none shadow-md font-header py-2">What is your rescheduling, cancellation and refunding policy for appointments ?</summary>
                              <p className="px-3 py-2 ">If you need to cancel your appointment, please call us at (phone number). Cancellation and rescheduling of appointment should be done within 24hrs , failure to adhere may attract extra charges. Refunding is only considered if a therapist didn’t show up at the time of your appointment.</p>
                         </details>
                         <details className="mb-3 shadow-md border border-gray-300">
                              <summary className="px-3 list-none shadow-md font-header py-2">This is the way to go about a FAQ header</summary>
                              <p className="px-3 py-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                         </details>

                         <h4 className="font-bold font-header text-xl mb-2 mt-10">Appointment</h4>
                         <details className="mb-3 shadow-md border border-gray-300">
                              <summary className="px-3 list-none shadow-md font-header py-2">how do I schedule an appointment?</summary>
                              <p className="px-3 py-2 ">Email us at Psychelapcare@gmail.com or send a DM to us on our platforms the following platform to schedule an appointment. Whatsapp or Instagram Or head to the appointment page on your dashboard to request an appointment.</p>
                         </details>
                         <details className="mb-3 shadow-md border border-gray-300">
                              <summary className="px-3 list-none shadow-md font-header py-2">How far out is scheduling ?</summary>
                              <p className="px-3 py-2 ">Our services run on west African time zone (24hrs 5 working days ) and we operate  between 10am -4pm on Saturday only but not on Sunday. You can also only schedule an appointment within the month. </p>
                         </details>
                         <details className="mb-3 shadow-md border border-gray-300">
                              <summary className="px-3 list-none shadow-md font-header py-2">How many sessions will I need to feel better?</summary>
                              <p className="px-3 py-2 ">Usually a minimum of 6 sessions is recommended for most therapeutic services, however you may need more depending on the severity of your symptoms. You can decide to pay for a session to see how it goes but we always advice you sign up for 3 sessions with us for a start for better experience as the first session is usually more of psychological evaluations, exploring possible areas of experiences and history taking to develop proper treatment plans for you.</p>
                         </details>

                         <h4 className="font-bold font-header text-xl mb-2 mt-10">Clinicians</h4>
                         <details className="mb-3 shadow-md border border-gray-300">
                              <summary className="px-3 list-none shadow-md font-header py-2">Will my appointment be an in-person appointment or telehealth appointment ?</summary>
                              <p className="px-3 py-2 ">Psychlapcare clinicians are seeing clients primarily via telehealth for now. Some clients are seen in-person and in the community base on special request and this will attract extra charges.</p>
                         </details>
                         <details className="mb-3 shadow-md border border-gray-300">
                              <summary className="px-3 list-none shadow-md font-header py-2">What age and population do your clinicians see ?</summary>
                              <p className="px-3 py-2 ">Psychelapcare serves  adolescents, and adults of all ages within and outside of Nigeria. We only assess and treat children with special needs based on special request.</p>
                         </details>
                         <details className="mb-3 shadow-md border border-gray-300">
                              <summary className="px-3 list-none shadow-md font-header py-2">What languages do your clinicians speak ? </summary>
                              <p className="px-3 py-2 ">Psychelapcare offers their services in English  Language</p>
                         </details>
                    </div>
               </section>
          </div>
     );
}

export default FaqPage;
