import React, { useRef, useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import Spinner from './../components/spinner';
import toast, { Toaster } from 'react-hot-toast';
import Footer from './../components/footer';

function RegisterPage() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const redirect = useHistory();
  const [resstatus, setresStatus] = useState('')

  const [email, changeEmail] = useState('')
  const [password, changePassword] = useState('')
  const [first_name, changeFirstName] = useState('')
  const [last_name, changeLastName] = useState('')
  const [loading, updateLoadingStatus] = useState(false)

  const [text_type, setTextType] = useState('password')
  const [toggleTextType, settoggleTextType] = useState(false)

  const [isnoError, setisnoError] = useState(true)
  const [Message, setMessage] = useState('')

  const first_name_ref = useRef();
  const last_name_ref = useRef();

  useEffect( () => {
       if(email.length > 12 && password.length > 4 && first_name.length > 3 && last_name.length > 3)
       {
            let button = document.querySelector('.login-submit-button')
            button.removeAttribute('disabled')
       }
  }, [email, password])


  const handleLogin = e => {
    const controller = new AbortController();
    e.preventDefault();
    const toastId = toast.loading('Loading...', {position: 'top-right'});
    updateLoadingStatus(true)

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: emailRef.current.value,
      Password: password,
      first_name: first_name,
      last_name: last_name
    });

    var requestOptions = {
      method: "POST",
      signal: controller.signal,
      headers: myHeaders,
      body: raw,
    };

     fetch("https://megapsyche-omisolaidowu.b4a.run/api/register", requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json();
        }
      })
      .then(result => {
           updateLoadingStatus(false)
            setresStatus(result.status)
            setMessage(result.message)
            if ( result.status === 0 ){
                 toast.error(result.message, {
                    id: toastId,
                  });
                 setMessage(result.message)
                 setresStatus(result.status)
            }else{
                 toast.success('Registration succesful', {
                    id: toastId,
                  });
                  localStorage.setItem("first_name", first_name)
                  setTimeout(() => {
                       redirect.push("/login")
                  }, 2000)
            }

        // return result
      })

      .catch(error => console.log("error", error))

  };
  const togglePasswordVisibility = () => {
       if(text_type == 'password')
       {
            setTextType('text')
            settoggleTextType(true)
    }else{
          setTextType('password')
          settoggleTextType(false)
    }
  }

  return (
    <div className="bg-primary min-h-screen">
    { loading ? <Spinner /> : ''}
    <Toaster/>
      <div className="h-[400px] md:h-[350px] py-3 px-10 w-full bg-black/50 text-primary bg-login-hero bg-blend-multiply bg-no-repeat md:bg-cover bg-contain">
           <h1 className='mb-3 mt-20 font-header text-5xl font-header text-primary'>Hello !</h1>
           <p className="text-primary text-lg font-text">Create a new account</p>
      </div>
      <div className="">
        <form onSubmit={handleLogin} className="mx-10 py-5 pb-10 -mt-32 px-4 mb-12 shadow-lg rounded-lg bg-white md:w-1/4 md:mx-auto md:px-10">
               <h3 className="font-header font-bold text-3xl text-secondary">Create an account</h3>
        {  resstatus !==1 ? <p className="errorMessage">{Message}</p>   : <p className="errorMessage">{Message}</p>  }
             <div className="mt-8 rounded-lg p-3 border border-gray-500 flex justify-start items-center gap-2 relative">
               <svg className="w-9 h-8 md:w-7 text-secondary"  data-slot="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"></path></svg>
               <input placeholder="First Name" required type="text" id="name" onChange={ (e) => changeFirstName(e.target.value)} className="p-3 bg-transparent px-4 text-md py-2 focus:outline-none w-full focus:bg-transparent"/>
             </div>
             <div className="mt-8 rounded-lg p-3 border border-gray-500 flex justify-start items-center gap-2 relative">
             <svg className="w-9 h-8 md:w-7 text-secondary"  data-slot="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"></path></svg>
               <input placeholder="Last Name" required type="text" id="name" onChange={ (e) => changeLastName(e.target.value)} className="p-3 bg-transparent px-4 text-md py-2 focus:outline-none w-full focus:bg-transparent"/>
             </div>
          <div className="mt-8 rounded-lg p-3 border border-gray-500 flex justify-start items-center gap-2 relative">
               <svg className="w-9 h-8 md:w-7 md:h-6 text-secondary" data-slot="icon" fill="none"  stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"></path></svg>
            <input placeholder="Email" required type="email" id="email" ref={emailRef} onChange={ (e) => changeEmail(e.target.value)} className="p-3 bg-transparent px-4 text-md py-2 focus:outline-none w-full focus:bg-transparent"/>
          </div>
          <div className="mt-8 rounded-lg p-3 border border-gray-500 flex justify-start items-center gap-2 relative">
            <svg className="w-9 h-8 md:w-7 text-secondary" data-slot="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"></path></svg>
            <input required type={text_type} id="password" ref={passwordRef} placeholder="Password" onChange={ (e) => changePassword(e.target.value)} className="p-3 bg-transparent px-4 text-md py-2 focus:outline-none w-full focus:bg-transparent"/>
              {
                   toggleTextType ? <svg data-slot="icon" onClick={togglePasswordVisibility} className="w-7 h-6 text-secondary absolute right-3 top-5 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"></path></svg> : <svg onClick={togglePasswordVisibility} className="w-7 h-6 text-secondary absolute right-3 top-5 cursor-pointer" data-slot="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"></path><path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"></path></svg>
              }
          </div>
          <div className="mt-10 flex justify-center ">
              <button className='px-12 py-3 rounded-lg disabled:bg-gray-400 disabled:text-gray-300 shadow-lg disabled:shadow-none bg-secondary text-primary login-submit-button cursor-pointer' disabled type="submit">Login</button>
          </div>
          <div className="flex justify-center items-center gap-4 my-3">
               <div className="h-1 w-1/3 bg-yellow-400"></div>
               <span>Or</span>
               <div className="h-1 w-1/3 bg-yellow-400"></div>
          </div>
          <p className="text-center text-secondary"><NavLink to="/login" className="anchorTag">Sign into your account</NavLink></p>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default RegisterPage;
