import SideBar from './../components/SideBar';

const Layout = (Component) => ({ ...props }) =>  {
     return (
          <main className="bg-primary px-5 md:px-0 md:overflow-hidden md:w-screen md:flex md:justify-start ">
               <SideBar />
               <section className="md:py-4 md:px-12">
                    <Component {...props} />
               </section>
          </main>
     );
}

export default Layout;
