import React, { useEffect, useState } from 'react';

function Spinner(props)
{
     useEffect( () => {
          if(props.data === true)
          {
               let spinner = document.querySelector('.loading-state')
               spinner.classList.toggle('showLoader')
          }
     }, [props.data]);

     return (
          <div className="loading-state"><div className="loading"></div></div>
     );
}

export default Spinner;
